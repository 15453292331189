<template>
  <div class="grey lighten-5">
    <v-row style="margin: 0">
      <v-col cols="12" sm="12" md="6">
        <div
          class="d-flex flex-column justify-center fill-height objective--text"
        >
          <p class="font-weight-medium text-h5 red--text text--accent-4">
            {{ objetive.title }}
          </p>
          <div class="divider"></div>
          <p class="body-1 black--text">
            {{ objetive.text }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <v-img ref="img_content" :src="src" height="400" :eager="true"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PreviewObjetive",
  data: () => ({
    src: "https://picsum.photos/720/400",
  }),
  props: { objetive: Object },
  watch: {
    objetive: {
      handler: function(val, oldVal) {
        this.src = val.src;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
