<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          Objetivo del sitio público
        </p>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <!-- Form -->
        <v-col cols="12" sm="12" md="4">
          <v-form>
            <v-container>
              <v-row
                v-if="
                  slcObjetive.id_article && searchInArray(actions, 'Editar')
                "
              >
                <div class="text-h6">
                  Editar Objetivo
                </div>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Título"
                    outlined
                    full-width
                    hide-details
                    v-model="slcObjetive.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    label="Descripción de Objetivo"
                    outlined
                    full-width
                    auto-grow
                    hide-details=""
                    v-model="slcObjetive.text"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    color="blue accent-4"
                    label="Archivo de imagen"
                    placeholder="Seleccione una imagen (675x600)"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    counter
                    outlined
                    clear-icon
                    :show-size="1000"
                    v-model="slcObjetive.image"
                    @change="previewUpdate"
                  >
                  </v-file-input>
                </v-col>
                <!-- <v-col cols="4">
                  <v-btn color="primary" text block @click="cleanUpdate">
                    Cancelar
                  </v-btn>
                </v-col> -->
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    @click="update"
                    :loading="isLoading"
                  >
                    Editar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !slcObjetive.id_article && searchInArray(actions, 'Agregar')
                "
              >
                <div class="text-h6">
                  Agregar Objetivo
                </div>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Título"
                    outlined
                    full-width
                    hide-details=""
                    v-model="newObjetive.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    label="Descripción de Objetivo"
                    outlined
                    full-width
                    auto-grow
                    hide-details=""
                    v-model="newObjetive.text"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    color="blue accent-4"
                    counter
                    label="Archivo de imagen"
                    placeholder="Seleccione una imagen"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    clear-icon
                    :show-size="1000"
                    v-model="newObjetive.image"
                    @change="previewAdd"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" text block @click="cleanAdd">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn
                    color="primary"
                    block
                    @click="add"
                    :loading="isLoading"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
          v-if="
            searchInArray(actions, 'Editar') ||
              searchInArray(actions, 'Agregar')
          "
        >
          <PreviewObjective
            :objetive="slcObjetive.id_article ? slcObjetive : newObjetive"
          />
        </v-col>
      </v-row>
      <v-row class="d-none">
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat height="50">
                  <v-toolbar-title class="text-h5">
                    Lista de objetivos de donación
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="selectObjetive(item)"
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectObjetive(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Activar/Desactivar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectObjetive(item);
                          changeStatus();
                        "
                        :color="
                          item.status == 1
                            ? 'light-blue darken-3'
                            : 'blue-grey darken-1'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status == 1
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                    <span>
                      {{ item.status == 1 ? "Activar" : "Desactivar" }}
                    </span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getObjetives">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text class="text-center">
          <p class="mb-0">¿Está seguro de eliminar este elemento?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">
            Cancelar
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteObjetiveConfirm"
            :loading="isLoading"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PreviewObjective from "./PreviewObjective.vue";
export default {
  name: "Objective",
  components: {
    PreviewObjective,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: "Título",
          value: "title",
        },
        {
          text: "Descripción",
          value: "text",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    newObjetive: {
      title: "Titulo",
      text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus laudantium mollitia corrupti.",
      src: "https://picsum.photos/720/400",
    },
    slcObjetive: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
  }),
  mounted() {
    this.getCurrentActions("Objetivo");
    this.getObjetives();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    getObjetives: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "objetive/all")
        .then(({ data }) => {
          this.data.data = [data];
          let vm = this;
          window.setTimeout(function() {
            vm.selectObjetive(data);
          }, 500);
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectObjetive(objetive) {
      let src = objetive.image;
      objetive.src = this.img + "objetive/" + src;
      objetive.image = null;
      this.slcObjetive = objetive;
    },

    cleanAdd() {
      this.newObjetive = {
        title: "Titulo",
        text:
          " Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus laudantium mollitia corrupti.",
        src: "https://picsum.photos/720/400",
      };
      this.getObjetives();
    },

    cleanUpdate() {
      this.slcObjetive = {};
      this.getObjetives();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "objetive", this.toFormData(this.newObjetive))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanAdd();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "objetive/" + this.slcObjetive.id_article,
          this.toFormData(this.slcObjetive)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    deleteObjetiveConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "article/" + this.slcObjetive.id_article)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "article/status/" + this.slcObjetive.id_article)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcObjetive = {};
      });
    },

    previewAdd(event) {
      this.newObjetive.src = URL.createObjectURL(event);
    },

    previewUpdate(event) {
      //debo forzar el update del preview de alguna manera
      this.slcObjetive.src = URL.createObjectURL(event);
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
